@use '@angular/material' as mat;
@import '@angular/material/theming';

$cropman-green: (
  50: #e3f4ea,
  100: #bbe4cc,
  200: #8ed3ac,
  300: #5cc38b,
  400: #2bb673,
  500: #00a95b,
  600: #009a51,
  700: #008844,
  800: #007739,
  900: #005825,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$cropman-blue: (
  50: #dff5fd,
  100: #ade6f9,
  200: #74d5f7,
  300: #2ac5f4,
  400: #00b8f4,
  500: #00acf4,
  600: #009ee5,
  700: #008bd2,
  800: #0079bf,
  900: #005a9f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$cropman-frontend-primary: mat-palette($cropman-green);
$cropman-frontend-secondary: mat-palette($cropman-blue);
$cropman-frontend-accent: mat-palette($cropman-green);

$cropman-frontend-warn: mat-palette($mat-red);

$cropman-frontend-theme: mat-light-theme(
  (
    color: (
      primary: $cropman-frontend-primary,
      accent: $cropman-frontend-accent,
      warn: $cropman-frontend-warn,
    ),
  )
);

@function cropman-mat-light-theme-foreground($color) {
  @return (
    base: rgba($color, 0.54),
    divider: rgba($color, 0.15),
    dividers: rgba($color, 0.15),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    hint-text: rgba($color, 0.38),
    secondary-text: rgba($color, 0.54),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 0.8),
    slider-min: rgba($color, 0.8),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}
$cropman-foreground: cropman-mat-light-theme-foreground(black);
$cropman-frontend-theme: map_merge(
  $cropman-frontend-theme,
  (
    foreground: $cropman-foreground,
  )
);

@function cropman-mat-light-theme-background($color) {
  @return (
    status-bar: map-get($mat-grey, 300),
    simfaz-bar: white,
    background: white,
    hover: rgba(black, 0.04),
    card: white,
    dialog: white,
    disabled-button: rgba(black, 0.12),
    raised-button: white,
    focused-button: rgba(black, 0.8),
    selected-button: map-get($mat-grey, 300),
    selected-disabled-button: map-get($mat-grey, 400),
    disabled-button-toggle: map-get($mat-grey, 200),
    unselected-chip: map-get($mat-grey, 300),
    disabled-list-option: map-get($mat-grey, 200),
    tooltip: rgba(black, 0.8)
  );
}
$cropman-background: cropman-mat-light-theme-background(
  mat-color($cropman-frontend-primary, 700)
);
$cropman-frontend-theme: map_merge(
  $cropman-frontend-theme,
  (
    background: $cropman-background,
  )
);

// $cropman-typography: mat-typography-config(
//   $font-family: 'Montserrat, sans-serif',
//   $headline: mat-typography-level(28px, 34px, 400),
//   $title: mat-typography-level(18px, 20px, 400),
//   $body-1: mat-typography-level(16px, 18px, 400),
// );

$cropman-typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $body-1:  mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  
  );

@include mat.typography-hierarchy($cropman-typography);



@mixin colors-theme($config-or-theme) {
  $primary: map-get($config-or-theme, primary);
  $accent: map-get($config-or-theme, accent);
  $warn: map-get($config-or-theme, warn);
  $foreground-config: map-get($config-or-theme, foreground);
  $base-color: map-get($foreground-config, text);
  $secondary-text-color: map-get($foreground-config, secondary-text);
  $disabled-text: map-get($foreground-config, disabled-text);
  $icon-color: map-get($foreground-config, icon);

  a,
  .crop-base {
    color: $base-color;
  }
  .crop-icon {
    color: $icon-color;
    --icon-color: #{$icon-color};
  }
  .crop-secondary-text {
    color: $secondary-text-color;
  }
  .crop-disabled-text {
    color: $disabled-text;
  }
  .crop-primary {
    color: mat-color($primary);
  }
  .crop-accent {
    color: mat-color($accent);
  }
  .crop-warn {
    color: mat-color($warn) !important;
  }
  .crop-primary-background {
    background-color: mat-color($primary);
  }
  .crop-accent-background {
    background-color: mat-color($accent);
  }
  .crop-warn-background {
    background-color: mat-color($warn);
  }
  .crop-primary-hover:hover {
    color: mat-color($primary);
  }
  .crop-accent-hover:hover {
    color: mat-color($accent);
  }
  .crop-warn-hover:hover {
    color: mat-color($warn);
  }
}

@include mat-core();
// @include mat-core($cropman-typography);
@include colors-theme($cropman-frontend-theme);
@include angular-material-theme($cropman-frontend-theme);

:root {
  --crop-brand-color: #{mat-color($cropman-frontend-primary, 500)};
  --crop-accent-color: #{mat-color($cropman-frontend-accent, 500)};
  --crop-text-color: #{map-get($cropman-foreground, text)};
  --crop-text-color-contrast: #fff;
  --crop-transition-timing: 0.2s;
  --crop-surface-2: #e9ebf4;
}
