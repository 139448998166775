@import 'src/assets/scss/theme';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.flex {
  display: flex;

  &--col {
    @extend .flex;
    flex-direction: column;
  }
}

.overline {
  font-size: 0.75rem;
  letter-spacing: 1.5px;
}

.cropman-sidebar {
  padding: 1.875rem 1.25rem;
}

main {
  padding: 0 1.25rem;
}
